import React from 'react'
import { Box } from '@achieve/sunbeam'
import styles from './GraphicCardList.module.scss'
import { Typography } from 'components/Contentful'
import classNames from 'classnames'

export const GraphicCardList = ({ content }) => {
  const title = content?.jsonContent?.jsonConfig?.title
  const list = content?.jsonContent?.jsonConfig?.list

  const getTable = () => {
    return list?.map((element, index) => (
      <Box className={styles['list']} data-marker={element.marker} key={`item-subtable-${index}`}>
        <Box>
          <Typography
            content={element.description}
            variant="bodyS30"
            fontWeight={element.marker ? 'medium' : 'regular'}
          />
        </Box>
        <Box className={styles['list-values']}>
          <Typography content={element.value} variant="displayXS30" fontWeight="bold" />
        </Box>
      </Box>
    ))
  }

  return (
    <Box className={classNames(content?.classNameContainer, styles['container'])}>
      <Box className={styles['title']}>
        <Typography content={title} variant="displayS10" fontWeight="medium" />
      </Box>
      <Box className={styles['body']}>{getTable()}</Box>
    </Box>
  )
}

export default GraphicCardList
